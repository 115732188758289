import { useCallback, useEffect, useState } from "react";
import DocumentStepElement from "./step-elements/document-step-element/document-step-element";
import SurveyStepElement from "./step-elements/survey-step-element/survey-step-element";
import ReportsStepElement from "./step-elements/reports-step-element/reports-step-element";
import { useActivities } from "../../../contexts/activities-context";
import { useService } from "../../../contexts/service-context";
import { useEthosNotification } from "../../../contexts/ethos-notification-context";
import ActivityModalContent from "../../../ui-components/ethos-modal/activity-modal-content/activity-modal-content";

const ServiceCustomerStep = ({
  step,
  service,
  fileTypes,
  customerDocuments = [],
  doUpdateSurvey,
  setCompleted,
  doDownloadFile,
  doUploadAndAssociateFile,
  doAssociateFiles,
  doRemoveFileFromService,
  doGenerateServiceCustomerReport,
  doSendServiceCustomerReport,
  customerId,
  doArchiveSreening,
  currentServiceStatus,
  docOption,
  customerScreeningInfo,
  dataActivity,
}) => {
  const [canProceed, setCanProceed] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { getServices } = useService();
  const [services, setServices] = useState();
  const [isScreening, setIsScreening] = useState(false);
  const { showSuccessToast, handleError } = useEthosNotification();
  const { saveNewActivity } = useActivities();
  const [activityData, setActivityData] = useState(null);
  const [internalName, setInternalName] = useState("");

  useEffect(() => {
    const checkSurveyResponseValid = (question) => {
      if (question.responseType === "Array") {
        if (!question.response || !question.response.length) {
          return !question.required;
        }
        for (var j = 0; j < question.response.length; j++) {
          for (var k = 0; k < question.response[j].length; k++) {
            if (!checkSurveyResponseValid(question.response[j][k])) {
              return false;
            }
          }
        }
        return true;
      }

      if (question.required) {
        return !!question.response;
      }
      return true;
    };

    if (step.requiredDocs && step.requiredDocs.length) {
      for (let i = 0; i < step.requiredDocs.length; i++) {
        const docType = step.requiredDocs[i];
        if (!step.documents || step.documents.filter((d) => d.docType === docType).length === 0) {
          setCanProceed(false);
          return;
        }
      }
    }

    if (step.survey && step.survey.length) {
      for (let i = 0; i < step.survey.length; i++) {
        if (!checkSurveyResponseValid(step.survey[i])) {
          setCanProceed(false);
          return;
        }
      }
    }

    if (step.requiredReports && step.requiredReports.length) {
      if (!step.reports) {
        setCanProceed(false);
        return;
      }
      for (let i = 0; i < step.requiredReports.length; i++) {
        const docType = step.requiredReports[i];
        var rep = step.reports.find((x) => x.docType === docType);
        if (!rep) {
          setCanProceed(false);
          return;
        }
      }
    }
    setCanProceed(true);
  }, [step]);

  const doSetCompleted = (step) => {
    if (canProceed) {
      setCompleted(step);
    }
  };

  const doUploadAndAssociateFileInner = (file, filename, expirationDate, type, selectedCustomer) => {
    doUploadAndAssociateFile(file, filename, expirationDate, type, step.key, selectedCustomer);
  };

  const doAssociateFilesInner = (files, type) => {
    var fileIds = files.map((x) => x._id);
    doAssociateFiles(fileIds, type, step.key);
  };

  const doGenerateServiceCustomerReportInner = async (templateId) => {
    await doGenerateServiceCustomerReport(templateId, step.key);
  };

  const updateSurvey = (survey) => {
    doUpdateSurvey(survey, step.key);
  };

  const getAnaviableService = useCallback(async () => {
    const { data, error } = await getServices();
    if (error) {
      handleError(error);
    }
    setServices(data.data);
  }, [getServices, handleError]);

  // const addNewServiteToCustomer = useCallback(
  //   async (service) => {
  //     const { error } = await newServiceCustomer(service);
  //     if (error) {
  //       handleError(error);
  //       return;
  //     }
  //     showSuccessToast("Servizio aggiunto correttamente");
  //   },
  //   [handleError, newServiceCustomer, showSuccessToast]
  // );

  const addCustomerActivity = async (newActivity) => {
    const { error } = await saveNewActivity(newActivity);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Attività registrata correttamente");
  };

  useEffect(() => {
    if (dataActivity !== null) {
      setActivityData(dataActivity);
    }
  }, [dataActivity]);

  useEffect(() => {
    if (step.key === "survey" || step.key === "setAppointment") {
      getAnaviableService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.key]);

  useEffect(() => {
    if (services && service) {
      const findName = services.find((e) => {
        return e._id === service._id;
      }).internalName;

      setInternalName(findName);

      setIsScreening(findName === "Screening");
    }
  }, [service, services]);

  return (
    <>
      {step.documents && (
        <>
          <DocumentStepElement
            readOnly={step.stepState === "COMPLETED"}
            keyName={"step_" + step.order + "_docs"}
            customerDocuments={customerDocuments}
            fileTypes={fileTypes}
            requiredDocs={step.requiredDocs ? step.requiredDocs : []}
            serviceFiles={step.documents}
            uploadAndAssociateFile={doUploadAndAssociateFileInner}
            associateFiles={doAssociateFilesInner}
            doRemoveFileFromService={doRemoveFileFromService}
            downloadFile={doDownloadFile}
            docOption={docOption}
            customerScreeningInfo={customerScreeningInfo}
          ></DocumentStepElement>
          <hr></hr>
        </>
      )}
      {step.survey && step.survey.length && (
        <>
          <SurveyStepElement
            customerId={customerId}
            availableServices={services}
            readOnly={step.stepState === "COMPLETED"}
            survey={step.survey}
            updateSurvey={updateSurvey}
            setUnsavedChanges={setUnsavedChanges}
            internalName={internalName || ""}
          ></SurveyStepElement>
          <hr></hr>
        </>
      )}

      {step.reports && (
        <>
          <ReportsStepElement
            readOnly={step.stepState === "COMPLETED"}
            service={service}
            step={step}
            generateReport={doGenerateServiceCustomerReportInner}
            sendReport={doSendServiceCustomerReport}
          ></ReportsStepElement>
          <hr></hr>
        </>
      )}

      <div className="row mb-3">
        <div className="col-10 offset-1">
          <div className="w-100 d-flex justify-content-center">
            {step.key === "setAppointment" && isScreening && (
              <>
                <div className="w-50">
                  <ActivityModalContent
                    customParams={{
                      configurations: activityData.configurations,
                      customer: activityData.customer,
                      activity: undefined,
                      customerId: activityData.customer._id,
                      ownerId: null,
                      users: activityData.users,
                    }}
                    isModal={false}
                    confirm={addCustomerActivity}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-8 offset-2 text-center mt-5">
          <div className="w-100">
            {step.stepState !== "COMPLETED" ? (
              <button disabled={!canProceed || unsavedChanges} onClick={() => doSetCompleted(step)} className="btn btn-secondary">
                Completa Step
              </button>
            ) : (
              <>
                {step.key === "completed" && isScreening ? (
                  <button
                    type="button"
                    disabled={currentServiceStatus === "ARCHIVED"}
                    onClick={() => {
                      doArchiveSreening();
                    }}
                    className="btn btn-secondary"
                  >
                    Archivia
                  </button>
                ) : (
                  <>
                    <button type="button" disabled className="btn btn-success">
                      Step completato
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCustomerStep;
