import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { useCustomer } from "../../contexts/customer-context";
import { useEthosNotification } from "../../contexts/ethos-notification-context";

interface Suggestion {
    _id: string;
    first_name: string;
    last_name: string;
}

interface Props {
    onSelectUser: (user: Suggestion) => void;
    initialUser?: { _id: string } | null;
    isDisabled?: boolean;
}

const AutoCompleteDropdown: React.FC<Props> = ({ onSelectUser, initialUser, isDisabled }) => {
    const { getCustomersForPractice, getSingleCustomer } = useCustomer();
    const { handleError } = useEthosNotification();
    const [selectedUser, setSelectedUser] = useState<Suggestion | null>(null);
    const [query, setQuery] = useState<string>("");
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [inputDisabled, setInputDisabled] = useState<boolean>(isDisabled || false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchSuggestions = useCallback(
        debounce(async (searchTerm: string) => {
            if (selectedUser) return;
            setLoading(true);

            try {
                const { data, error } = await getCustomersForPractice(searchTerm);
                if (error !== null) {
                    handleError(error);
                    return;
                }

                setSuggestions(data || []);
            } catch (error) {
                console.error("Errore nel recupero dei dati:", error);
            } finally {
                setLoading(false);
                setShowDropdown(true);
            }

        }, 500),
        [selectedUser]
    );

    useEffect(() => {
        if (query.length >= 4) {
            fetchSuggestions(query);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const handleSelect = (user: Suggestion) => {
        setSelectedUser(user);
        setQuery(`${user.first_name} ${user.last_name}`);
        setShowDropdown(false);
        onSelectUser(user);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || "";
        setQuery(value);
        if (!value) {
            setSelectedUser(null);
            setInputDisabled(false);
        }
    };

    useEffect(() => {
        if (initialUser._id) {
            setInputDisabled(true);
            getSingleCustomer(initialUser._id).then(({ data, error }) => {

                if (error !== null) {
                    handleError(error);
                    return;
                }

                setSelectedUser(data);
                setQuery(`${data.first_name} ${data.last_name}`);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleError]);

    return (
        <div className="position-relative">
            <div className="position-relative">
                <label htmlFor="search">Cliente *</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Inserisci almeno 4 caratteri"
                    value={query}
                    onChange={handleInputChange}
                    disabled={loading}
                    readOnly={inputDisabled}
                />
            </div>

            {showDropdown && suggestions.length > 0 && (
                <ul className="list-group position-absolute w-100 mt-1 shadow" style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {suggestions.map((item) => (
                        <li
                            key={item._id}
                            className="list-group-item list-group-item-action"
                            onClick={() => handleSelect(item)}
                        >
                            {`${item.first_name} ${item.last_name}`}
                        </li>
                    ))}
                </ul>
            )}

            {showDropdown && suggestions.length === 0 && (
                <ul className="list-group position-absolute w-100 mt-1 shadow">
                    <li
                        className="list-group-item "
                    >
                        <strong>Nessun cliente trovato</strong>
                    </li>

                </ul>
            )}

        </div>
    );
};

export default AutoCompleteDropdown;
