import React, { useEffect, useState } from 'react';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../ui-components/custom-components';
import { InputNumber } from '../../../../../ui-components/forms';

export enum CalcsType {
	INTERESTS = "INTERESTS",
	INSTALLMENTS = "INSTALLMENTS"
};

type FilterItem = {
	key: CalcsType;
	label: string;
};

export type CalcsData = {
	haveCalcs: boolean,
	nRate: number | null,
	nRateMax: number | null,
	percDur: number | null,
	percSpes: number | null,
	installmentMonthly: number | null,
	expenseCommFin: number | null,
	expenseCommInt: number | null,
	expenseAssRim: number | null,
	expenseImpost: number | null,
	spesa: number | null
	spesaLabel: string,
	expenseAssRimLabel: string,
	expenseCommFinLabel: string,
	expenseCommIntLabel: string,
	expenseImpostLabel: string,
	installmentMonthlyLabel: string,
	editAss: number | null,
	editCommBanc: number | null,
	editCommInt: number | null,
	editSpesa: number | null,
	rncImport: number | null,
	rncImportLett: string,
	importLett: string,
	calcsType: string | null
}

interface CalsModal {
	data: CalcsData
	newData: (newData: CalcsData) => void
	closeModal: (value: boolean) => void
}

const CalcsModal = ({ data, newData, closeModal }: CalsModal) => {
	const [nRate, setNRate] = useState<number>(data?.nRate ?? 0);
	const [nRateMax, setNRateMax] = useState<number>(data?.nRateMax ?? 0);
	const [percDur, setPercDur] = useState<number>(data?.percDur ?? 0);
	const [percSpes, setPercSpes] = useState<number>(data?.percSpes ?? 0);
	const [installmentMonthly, setInstallmentMonthly] = useState<number>(data?.installmentMonthly ?? 0);
	const [expenseCommFin, setExpenseCommFin] = useState<number>(data?.expenseCommFin ?? 0);
	const [expenseCommInt, setExpenseCommInt] = useState<number>(data?.expenseCommInt ?? 0);
	const [expenseAssRim, setExpenseAssRim] = useState<number>(data?.expenseAssRim ?? 0);
	const [expenseImpost, setExpenseImpost] = useState<number>(data?.expenseImpost ?? 0);
	const [spesa, setSpesa] = useState<number>(data?.spesa ?? 0);

	const [selectedCalcsType, setSelectedCalcsType] = useState<string>(data.calcsType ?? CalcsType.INSTALLMENTS);

	const [installmentMonthlyLabel, setInstallmentMonthlyLabel] = useState<string>(data.installmentMonthlyLabel ?? "")
	const [expenseCommFinLabel, setExpenseCommFinLabel] = useState<string>(data?.expenseCommFinLabel ?? '')
	const [expenseCommIntLabel, setExpenseCommIntLabel] = useState<string>(data.expenseCommIntLabel ?? "")
	const [expenseAssRimLabel, setExpenseAssRimLabel] = useState<string>(data?.expenseAssRimLabel ?? '')
	const [expenseImpostLabel, setExpenseImpostLabel] = useState<string>(data.expenseImpostLabel ?? "")
	const [spesaLabel, setSpesaLabel] = useState<string>(data?.spesaLabel ?? "")

	const customFilters: FilterItem[] = [
		{ key: CalcsType.INSTALLMENTS, label: "Rate" },
		{ key: CalcsType.INTERESTS, label: "Interessi" }
	];

	const handleSaveData = () => {
		newData({
			nRate,
			nRateMax,
			percDur,
			percSpes,
			installmentMonthly,
			expenseCommFin,
			expenseCommInt,
			expenseAssRim,
			expenseImpost,
			spesa,
			installmentMonthlyLabel,
			expenseCommFinLabel,
			expenseCommIntLabel,
			expenseAssRimLabel,
			expenseImpostLabel,
			spesaLabel,
			haveCalcs: true,
			editAss: data.editAss,
			editCommBanc: data.editCommBanc,
			editCommInt: data.editCommInt,
			editSpesa: data.editSpesa,
			rncImport: data.rncImport,
			rncImportLett: data.rncImportLett,
			importLett: data.importLett,
			calcsType: selectedCalcsType,
		})
		closeModal(false)
	}


	useEffect(() => {
		const nRateMaxFix = nRateMax || 1;
		setPercDur(nRate / nRateMaxFix);
		setPercSpes(1 - (nRate / nRateMaxFix));
	}, [nRateMax, nRate])

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Genera Calcoli</h3>
					<button className="close-button" onClick={() => closeModal(false)}>&times;</button>
				</div>
				<hr />

				<div className="d-flex gap-2 my-3 align-items-center">
					<span className='navbar-brand'>Tipologia di calcolo</span>
					<div className="btn-group" role="group" aria-label="View predefinite">
						<div className="btn-group" role="group" aria-label="View predefinite">
							{customFilters.map((item) => (
								<button
									key={item.key}
									className={"btn " + (item.key === selectedCalcsType ? "btn-primary" : "btn-outline-secondary")}
									onClick={() => setSelectedCalcsType(item.key)}
								>
									{item.label}
								</button>
							))}
						</div>
					</div>

				</div>

				{/* First step */}
				<div className="step">
					<p className="fw-bold">Numero {selectedCalcsType === CalcsType.INSTALLMENTS ? "rate" : "interessi contrattualizzati"} da pagare:</p>
					<div className="mb-3">
						<div className="row align-items-center gap-2">
							<div className={`col-12 col-md-6 col-lg-${selectedCalcsType === CalcsType.INSTALLMENTS ? '2' : '3'}`}>
								<InputNumber defaultValue={nRate} type="number" price={selectedCalcsType === CalcsType.INTERESTS ? true : false} name="n-rate" step="1" onChange={(e: string) => { setNRate(parseFloat(e)) }} label={undefined} />
							</div>
							<div style={{ display: "contents" }}>
								SU
							</div>
							<div className={`col-12 col-md-6 col-lg-${selectedCalcsType === CalcsType.INSTALLMENTS ? '2' : '3'}`}>
								<InputNumber defaultValue={nRateMax} type="number" price={selectedCalcsType === CalcsType.INTERESTS ? true : false} name="n-rate-max" step="1" onChange={(e: string) => { setNRateMax(parseFloat(e)) }} label={undefined} />
							</div>
						</div>
					</div>

					<p><strong>Percentuale Durata Contratto:</strong> {`${percDur.toFixed(1)} / ${(percDur * 100).toFixed(0)}%`}</p>
					<p><strong>Percentuale Spese da Stornare:</strong> {`${percSpes.toFixed(1)} / ${(percSpes * 100).toFixed(0)}%`}</p>
				</div>

				<hr />

				{/* Secondo step */}
				<div className="step">
					<div className="row">
						{/* Installment Monthly */}
						<div className="col-12 col-md-6 col-lg-4 mt-3">
							<label htmlFor={"installmentMonthly"}>Importo della Rata</label>
							<input id="installmentMonthlyLabel" title="installmentMonthlyLabel" style={{ width: "100%" }} className='mb-1 py-1' defaultValue={installmentMonthlyLabel} onChange={(e) => { setInstallmentMonthlyLabel(e.target.value) }} />
							<div className="input-group flex-nowrap">
								<span className="input-group-text">&euro;</span>
								<input id='installmentMonthly' name='installmentMonthly' className={'form-control'} defaultValue={installmentMonthly} placeholder={"placeholder"} type={"number"} step={"0.1"} min={0} onChange={(e) => setInstallmentMonthly(parseFloat(e.target.value))} />
							</div>
						</div>

						{/* Expense Comm Fin */}
						<div className="col-12 col-md-6 col-lg-4 mt-3">
							<label htmlFor="expenseCommFin">Spesa Commissione Finanziaria</label>
							<input id="expenseCommFinLabel" title="expenseCommFinLabel" style={{ width: "100%" }} className='mb-1 py-1' defaultValue={expenseCommFinLabel} onChange={(e) => { setExpenseCommFinLabel(e.target.value) }} />
							<div className="input-group flex-nowrap">
								<span className="input-group-text">&euro;</span>
								<input id='expenseCommFin' name='expenseCommFin' className={'form-control'} defaultValue={expenseCommFin} placeholder={"placeholder"} type={"number"} step={"0.1"} min={0} onChange={(e) => setExpenseCommFin(parseFloat(e.target.value))} />
							</div>
						</div>

						{/* Expense Comm Int */}
						<div className="col-12 col-md-6 col-lg-4 mt-3">
							<label htmlFor="expenseCommInt">Spesa Commissione Intermedio del Credito</label>
							<input id="expenseCommIntLabel" title="expenseCommIntLabel" style={{ width: "100%" }} className='mb-1 py-1' defaultValue={expenseCommIntLabel} onChange={(e) => { setExpenseCommIntLabel(e.target.value) }} />
							<div className="input-group flex-nowrap">
								<span className="input-group-text">&euro;</span>
								<input id='expenseCommInt' name='expenseCommInt' className={'form-control'} defaultValue={expenseCommInt} placeholder={"placeholder"} type={"number"} step={"0.1"} min={0} onChange={(e) => setExpenseCommInt(parseFloat(e.target.value))} />
							</div>
						</div>

						{/* Expense Ass Rim */}
						<div className="col-12 col-md-6 col-lg-4 mt-3">
							<label htmlFor="expenseAssRim">Spesa Assicurazione Rimborso Credito</label>
							<input id="expenseAssRimLabel" title="expenseAssRimLabel" style={{ width: "100%" }} className='mb-1 py-1' defaultValue={expenseAssRimLabel} onChange={(e) => { setExpenseAssRimLabel(e.target.value) }} />
							<div className="input-group flex-nowrap">
								<span className="input-group-text">&euro;</span>
								<input id='expenseAssRim' name='expenseAssRim' className={'form-control'} defaultValue={expenseAssRim} placeholder={"placeholder"} type={"number"} step={"0.1"} min={0} onChange={(e) => setExpenseAssRim(parseFloat(e.target.value))} />
							</div>
						</div>

						{/* Spesa */}
						<div className="col-12 col-md-6 col-lg-4 mt-3">
							<label htmlFor="spesa">Spese Istruttoria</label>
							<input id="spesaLabel" title="spesaLabel" style={{ width: "100%" }} className='mb-1 py-1' defaultValue={spesaLabel} onChange={(e) => { setSpesaLabel(e.target.value) }} />
							<div className="input-group flex-nowrap">
								<span className="input-group-text">&euro;</span>
								<input id='spesa' name='spesa' className={'form-control'} defaultValue={spesa} placeholder={"placeholder"} type={"number"} step={"0.1"} min={0} onChange={(e) => setSpesa(parseFloat(e.target.value))} />
							</div>
						</div>

						{/* Expense Impost */}
						<div className="col-12 col-md-6 col-lg-4 mt-3">
							<label htmlFor="expenseImpost">Spesa Imposta di Bollo</label>
							<input id="expenseImpostLabel" title="expenseImpostLabel" style={{ width: "100%" }} className='mb-1 py-1' defaultValue={expenseImpostLabel} onChange={(e) => { setExpenseImpostLabel(e.target.value) }} />
							<div className="input-group flex-nowrap">
								<span className="input-group-text">&euro;</span>
								<input id='expenseImpost' name='expenseImpost' className={'form-control'} defaultValue={expenseImpost} placeholder={"placeholder"} type={"number"} step={"0.1"} min={0} onChange={(e) => setExpenseImpost(parseFloat(e.target.value))} />
							</div>
						</div>

					</div>
				</div>
				<hr />

				<button type="button" className="btn btn-primary" onClick={() => {
					handleSaveData()
				}}>Conferma</button>

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default CalcsModal;