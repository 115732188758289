export enum EnumQuestionOptionResponseType {
  "String" = "String",
  "Array" = "Array",
  "Select" = "Select",
  "SelectBank" = "SelectBank",
  "Date" = "Date",
  "Money" = "Money",
  "Number" = "Number",
  "Text" = "Text",
  "TextArea" = "TextArea",
  "Id" = "Id",
  "BankPEC" = 'BankPEC',
  "SelectAgente" = 'SelectAgente',
}

export interface QuestionOption {
  order: number;
  question: string;
  required: boolean;
  response: string;
  responseType: EnumQuestionOptionResponseType;
}

export interface Question {
  options: QuestionOption[];
  order: number;
  question: string;
  required: boolean;
  hidden?: boolean;
  isBlockName: boolean;
  isServiceName: boolean;
  response: any;
  responseType: EnumQuestionOptionResponseType;
  services?: { enabled: boolean, items: any[] }
}

export interface Section {
  title: string,
  order: number,
  layout?: { type: "tabs", name: string }
  questions: Question[]
}