import { InputNumber, InputText } from "../../../../../ui-components/forms"
import { useState } from "react";
import CalcsModal, { CalcsData, CalcsType } from "./calcs-modal";

interface CalcsStepElement {
    title: string,
    data: CalcsData
    updateCalcs: (data: CalcsData) => void
}

const CalcCqsStepElement = ({ title, data, updateCalcs }: CalcsStepElement) => {
    const [currentData, setCurrentData] = useState<CalcsData>(data);
    const [showCalcs, setShowCalcs] = useState<boolean>(false);

    const [editAss, setEditAss] = useState<number>(data.editAss ?? 0);
    const [editCommBanc, setEditCommBanc] = useState<number>(data.editCommBanc ?? 0);
    const [editCommInt, setEditCommInt] = useState<number>(data.editCommInt ?? 0);
    const [editSpesa, setEditSpesa] = useState<number>(data.editSpesa ?? 0);

    const [importLett, setImportLett] = useState<string>(data.importLett ?? "");
    const [rncImport, setRncImport] = useState<number>(data.rncImport ?? 0)
    const [rncImportLett, setRncImportLett] = useState<string>(data.rncImportLett ?? "");

    return (
        <>
            <div className="row">
                <h4 style={{ textAlign: "center" }}>{title}</h4>
                <div className="d-flex col-12 justify-content-end">
                    <button
                        className={"btn btn-primary"}
                        onClick={() => setShowCalcs(!showCalcs)}
                    >
                        Genera calcoli
                    </button>
                </div>

                {currentData.haveCalcs ? <>
                    <div className="col-12 body my-3">
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Ristorni</th>
                                    <th></th>
                                    <th>Già Stornati</th>
                                    <th></th>
                                    <th>Da Richiedere</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{currentData.expenseCommFinLabel}</td>
                                    <td>&euro; {(currentData.expenseCommFin * currentData.percSpes).toFixed(2)}</td>
                                    <td><input type="number" step={"0.01"} defaultValue={currentData.editCommBanc} onChange={(e) => { setEditCommBanc(parseFloat(e.target.value)) }} /></td>
                                    <td></td>
                                    <td>&euro; {((currentData.expenseCommFin * currentData.percSpes) - editCommBanc).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>{currentData.expenseCommIntLabel}</td>
                                    <td>&euro; {(currentData.expenseCommInt * currentData.percSpes).toFixed(2)}</td>
                                    <td><input type="number" step={"0.01"} defaultValue={currentData.editCommInt} onChange={(e) => { setEditCommInt(parseFloat(e.target.value)) }} /></td>
                                    <td></td>
                                    <td>&euro; {((currentData.expenseCommInt * currentData.percSpes) - editCommInt).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>{currentData.expenseAssRimLabel}</td>
                                    <td>&euro; {(currentData.expenseAssRim * currentData.percSpes).toFixed(2)}</td>
                                    <td><input type="number" step={"0.01"} defaultValue={currentData.editAss} onChange={(e) => { setEditAss(parseFloat(e.target.value)) }} /></td>
                                    <td></td>
                                    <td>&euro; {((currentData.expenseAssRim * currentData.percSpes) - editAss).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>{currentData.spesaLabel}</td>
                                    <td>&euro; {(currentData.spesa * currentData.percSpes).toFixed(2)}</td>
                                    <td><input type="number" step={"0.01"} defaultValue={currentData.editSpesa} onChange={(e) => { setEditSpesa(parseFloat(e.target.value)) }} /></td>
                                    <td></td>
                                    <td>&euro; {((currentData.spesa * currentData.percSpes) - editSpesa).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><strong>Totale</strong></td>
                                    <td><strong>&euro; {(((currentData.expenseCommFin * currentData.percSpes) - editCommBanc) + ((currentData.expenseCommInt * currentData.percSpes) - editCommInt) + ((currentData.expenseAssRim * currentData.percSpes) - editAss) + ((currentData.spesa * currentData.percSpes) - editSpesa)).toFixed(2)}</strong></td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><strong>Importo in Lettere</strong></td>
                                    <td><input type="text" defaultValue={importLett} onChange={e => setImportLett(e.target.value)} /></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={5} className="text-muted small">Calcoli generati seguendo il rapporto fra il numero di {currentData.calcsType === CalcsType.INSTALLMENTS ? "rate pagate" : "interessi contrattualizzati e quelli pagati"} </td>
                                </tr>
                            </tfoot>
                        </table>

                        <hr />

                        <h5 className="fw-bold">Rate non contabilizzate</h5>
                        <div className="row">
                            <div className="col-md-3">
                                <InputNumber validation={null} price={true} step="0.01" type="number" label="Importo" name="rncImport" defaultValue={rncImport} onChange={setRncImport} />
                            </div>
                            <div className="col-md-3">
                                <InputText label="Importo in Lettere" name="rncImportLett" defaultValue={rncImportLett} onChange={setRncImportLett} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <button
                            className={"btn btn-primary"}
                            onClick={() => {
                                updateCalcs({
                                    ...currentData, editAss,
                                    editCommBanc,
                                    editCommInt,
                                    editSpesa,
                                    importLett,
                                    rncImport,
                                    rncImportLett
                                })
                            }}
                        >
                            Conferma
                        </button>
                    </div>
                </> : null}

                <hr className="mt-3" />
            </div>

            {showCalcs && <CalcsModal closeModal={(value) => setShowCalcs(value)} data={currentData} newData={(newData) => { setCurrentData(newData) }} />}
        </>
    )
}

export default CalcCqsStepElement