import { useEffect, useState } from 'react';
import { useEthosModal } from '../../../../../contexts/ethos-modal-context';
import { useConfigurations } from '../../../../../contexts/configurations-context';
import { useEthosNotification } from '../../../../../contexts/ethos-notification-context';
import { useFiles } from '../../../../../contexts/files-context';

const ReportsStepElement = ({ readOnly = false, step, service, generateReport, sendReport, overrideClass }) => {

	const { configurations } = useConfigurations();
	const { showErrorToast, handleError } = useEthosNotification();
	const { downloadFile, getFilesByIds, getFileInfoById, getAsyncOperationById, getAsyncOperations } = useFiles();
	const [templatesInfo, setTemplatesInfo] = useState(null);
	const [templateFileIds, setTemplateFileIds] = useState(null);
	const [reportsList, setReportsList] = useState(null);
	const { openDocumentListModal, openStepReportListModal } = useEthosModal();

	useEffect(() => {
		async function loadReports() {

			if (!step || !configurations || !configurations.typeReport || !step.reports || !step.reports.length) {
				return;
			}

			const reportsFileIds = step.reports.map(r => r.id);

			var { data, error } = await getFilesByIds(reportsFileIds);
			if (error) {
				handleError(error);
				return;
			}

			var lista = [];
			for (var i = 0; i < configurations.typeReport.length; i++) {
				const item = configurations.typeReport[i];
				var obj = {};
				obj.reportType = item.value;
				obj.reportTypeLabel = item.label;
				obj.required = step.requiredReports.includes(item.value);
				const filteredReports = step.reports.filter(x => x.docType === item.value);
				for (var j = 0; j < filteredReports.length; j++) {
					const report = filteredReports[j];
					var fileInfo = data.data.find(x => x._id === report.id);
					if (fileInfo) {
						report.filename = fileInfo.filename;
						report.createdAt = fileInfo.createdAt;
						report.updatedAt = fileInfo.updatedAt;
						report.expirationDate = fileInfo.expirationDate;
						report.AsyncOpData = fileInfo.AsyncOpData;
					}
					report.reportType = obj.reportType;
					report.reportTypeLabel = obj.reportTypeLabel;
				}
				obj.associatedReports = filteredReports;
				lista.push(obj);
			}
			setReportsList(lista);
		}
		loadReports();
	}, [step, configurations, getFilesByIds, handleError]);

	useEffect(() => {
		async function loadTemplatesInfo() {
			if (!templateFileIds || !templateFileIds.length) return;
			var { data, error } = await getFilesByIds(templateFileIds);
			if (error) {
				handleError(error);
				return;
			}
			setTemplatesInfo(data.data.map(f => {
				const templateType = configurations.typeTemplate?.find(tt => tt.value === f.fileType);
				return {
					...f,
					templateTypeLabel: templateType ? templateType.label : 'N.D.'
				}
			}));
		}
		loadTemplatesInfo();
	}, [templateFileIds, getFilesByIds, handleError, configurations]);

	// const init = useCallback(() => {
	// 	loadFiles();
	// }, [loadFiles]);


	// useEffect(() => {
	// 	init();
	// }, [setFullFilesInfo]);


	useEffect(() => {
		if (!service || !service.templates) { return };
		setTemplateFileIds(service.templates.filter(t => t.status === 'ACTIVE').map(t => t.id));
	}, [service]);

	const doGenerateReport = async () => {
		var callback = async (files) => {
			if (!files || !files.length) {
				return;
			}
			await generateReport(files[0]._id);
			// init();
		}
		var customColumns = [];
		customColumns.push({ value: "templateTypeLabel", label: "Tipo Template" })
		openDocumentListModal(templatesInfo, null, false, true, callback, doDownloadFile, null, "Seleziona il template da utilizzare per il nuovo report", null, true, customColumns, null);
	}

	const doViewReports = async (reportTypeElement) => {
		var sendCallback = async (file) => {
			sendReport(file.id);
		}
		openStepReportListModal(reportTypeElement.associatedReports, doDownloadFile, sendCallback, getFileInfoById, getAsyncOperationById, getAsyncOperations, "Lista dei report di tipo '" + reportTypeElement.reportTypeLabel + "' generati per il servizio")
	}

	const doDownloadFileById = async (id) => {
		const { error } = await downloadFile(id);
		if (error) {
			showErrorToast("Errore durante il download del file!");
			return;
		}
		// startDownload(data);
	}

	const doDownloadFile = async (f) => {
		doDownloadFileById(f._id || f.id);
	}

	return (
		<>
			<div className="row">
				{
					<>
						<div className="col-8 offset-2">
							{(reportsList) &&
								<table className="table table-striped">
									<tbody>
										{
											reportsList.map(function (el, tIndex) {
												return (
													(el.associatedReports.length || el.required) ?
														<tr key={"report_type_element_" + (tIndex)}>
															<td >
																{el.reportTypeLabel} ({el.associatedReports.length}) {el.required && <span className="fw-bold text-danger">*</span>}
															</td>
															<td style={{ width: '40%' }}>
																<div className="btn-group w-100" role="group">
																	{el.associatedReports.length ?
																		<button onClick={() => doViewReports(el)} className="btn btn-sm btn-secondary">Vedi reports</button>
																		: <button disabled className="btn btn-sm btn-secondary">Vedi reports</button>
																	}
																</div>
															</td>
														</tr> : null
												)
											}
											)
										}
									</tbody>
								</table>
							}
						</div>
						{
							!readOnly &&
							<div className="col-4 offset-4">
								<div className="btn-group w-100" role="group">
									<span onClick={() => doGenerateReport()} className="btn btn-sm btn-primary">Genera nuovo report per il servizio</span>
								</div>
							</div>
						}
					</>
				}
			</div>
		</>
	)
}

export default ReportsStepElement;