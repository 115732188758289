import { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import { useCustomer } from "../../../contexts/customer-context";
import { useUser } from "../../../contexts/user-context";
import NewCustomer from "../new-customer";
import { renderPaginator } from "../../../utils/functions";
import "./styles.css";
import { useEthosNotification } from "../../../contexts/ethos-notification-context";
import { useEthosModal } from "../../../contexts/ethos-modal-context";
import { useConfigurations } from "../../../contexts/configurations-context";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useActivities } from "../../../contexts/activities-context";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faFileCsv, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useService } from "../../../contexts/service-context";

const ListCustomers = (props) => {
  const { user } = useAuthenticator();
  const { getUsersPractice } = useUser();
  const { openUploadFileModal, showUploadProspectsResultModal } = useEthosModal();
  const { getCustomers, deleteCustomer, uploadProspects, changeCustomerStatus, addCustomerStrike, generateCSVFromTable } = useCustomer();
  const { saveNewActivity } = useActivities();
  const [customers, setCustomers] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [search, setSearch] = useState(null);
  const [myUser, setMyUser] = useState(null);
  const [activeCustomFilter, setActiveCustomFilter] = useState("");
  const [users, setUsers] = useState([]);
  const LIMIT = 12;
  const [offset, setOffset] = useState(0);
  const [paginator, setPaginator] = useState(null);
  const searchRef = useRef();
  const userRef = useRef();
  const customerStatusRef = useRef();
  const { configurations } = useConfigurations();
  const { getServices } = useService();
  const [services, setServices] = useState([]);
  const [customFilters, setCustomFilters] = useState([]);
  const [customerStatus, setCustomerStatus] = useState([]);
  const { handleError, showSuccessToast, showErrorToast, registerPendingRequest, unregisterPendingRequest } = useEthosNotification();
  const { openEthosConfirmModal, openActivityModal, openExpiredTranchesArrayModal } = useEthosModal();
  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const role =
    user &&
    user.signInUserSession &&
    user.signInUserSession.accessToken &&
    user.signInUserSession.accessToken.payload &&
    user.signInUserSession.accessToken.payload["cognito:groups"] &&
    user.signInUserSession.accessToken.payload["cognito:groups"].length
      ? user.signInUserSession.accessToken.payload["cognito:groups"][0]
      : "CLIENTE";

  const [sortColumn, setSortColumn] = useState({ column: null, direction: 0 });

  const [sortFilter, setSortFilter] = useState(null);

  const clearFilter = useCallback(() => {
    setSearch(null);
    setMyUser(null);
    setActiveCustomFilter(null);
    setOffset(0);
    setSortFilter(null);
  }, []);

  const loadCustomers = useCallback(async () => {
    const { data, error, length } = await getCustomers(search, activeCustomFilter, myUser, LIMIT, offset, sortFilter);
    if (error) {
      handleError(error);
      return;
    }
    setCustomers(data);
    setTotalCustomers(length);
  }, [activeCustomFilter, getCustomers, handleError, myUser, offset, search, sortFilter]);

  const loadServices = useCallback(async () => {
    const { data, error } = await getServices();
    if (error) {
      handleError(error);
    }
    setServices(data.data);
  }, [getServices, handleError]);

  useEffect(() => {
    renderPaginator(totalCustomers, LIMIT, setOffset, setPaginator, offset);
  }, [totalCustomers, offset]);

  useEffect(() => {
    setOffset(0);
  }, [activeCustomFilter, search, user, myUser]);

  useEffect(() => {
    if (configurations && configurations.typeCustomerFilter) {
      var filters = [];
      const obj = {};
      obj.label = "Tutti";
      obj.key = "";
      filters.push(obj);
      for (var i = 0; i < configurations.typeCustomerFilter.length; i++) {
        const item = configurations.typeCustomerFilter[i];
        filters.push({ label: item.label, key: item.value });
      }
      setCustomFilters(filters);
    }

    if (configurations && configurations.customerStatus) {
      var filtersCustomerStatus = [];
      for (var m = 0; m < configurations.customerStatus.length; m++) {
        const item = configurations.customerStatus[m];
        filtersCustomerStatus.push({ label: item.label, key: item.value });
      }
      setCustomerStatus(filtersCustomerStatus);
    }
  }, [configurations]);

  const getUsers = useCallback(async () => {
    const { data } = await getUsersPractice();
    setUsers(data);
  }, [getUsersPractice]);

  useEffect(() => {
    registerPendingRequest();
    loadCustomers();
    getUsers();
    loadServices();
    unregisterPendingRequest();
  }, [offset, getUsers, registerPendingRequest, loadCustomers, loadServices, unregisterPendingRequest]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const resetView = () => {
    clearFilter();
  };

  const handleDeleteCustomer = async (id) => {
    const deleteCustomerCallback = async (inp) => {
      const { error } = await deleteCustomer(inp);
      if (error !== null) {
        handleError(error);
        return;
      }
      showSuccessToast("Cliente eliminato correttamente!");
      loadCustomers();
    };

    openEthosConfirmModal("Sicuro di voler eliminare il cliente?", deleteCustomerCallback, id, "Conferma eliminazione");
  };

  const handleSearch = async () => {
    loadCustomers();
  };

  const openUploadProspectsModal = () => {
    const doUploadProspects = async (file, filename, expirationDate) => {
      const { data, error } = await uploadProspects(file);
      if (!!data) {
        showUploadProspectsResultModal("", data);
      } else if (error) {
        showErrorToast("Errore durante l'import!");
        return;
      }
      resetView();
    };
    openUploadFileModal("Carica un file CSV per inserire i prospects a sistema", false, false, doUploadProspects, null);
  };

  const showStrikes = () => {
    return activeCustomFilter === "PROSPECTS" || activeCustomFilter === "CUSTOMERS";
  };

  const canBeSetToDiscarded = (cust) => {
    return cust.status === "PROSPECT" || cust.status === "REMARKETING";
  };

  const canBeSetToProspect = (cust) => {
    return cust.status === "DISCARDED" || cust.status === "LOST";
  };

  const canBeSetToLead = (cust) => {
    return cust.status === "PROSPECT";
  };

  const canBeSetToCustomer = (cust) => {
    return cust.status === "CLOSED" || cust.status === "CONTENZIOSO";
  };

  const canBeSetToClosed = (cust) => {
    return cust.status !== "CLOSED" ;
  };

  const canBeSetToRemarketing = (cust) => {
    const validStatuses = ["PROSPECT", "LEAD", "CUSTOMER", "CLOSED"];
    return validStatuses.includes(cust.status);
  };

  const canBeSetToContenzioso = (cust) => {
    const validStatuses = ["CUSTOMER", "CLOSED"];
    return validStatuses.includes(cust.status);
  };

  const canAddActivity = (cust) => {
    return cust.status === "PROSPECT";
  };

  const canAddStrike = (cust) => {
    return cust.status === "PROSPECT";
  };

  const setCustomerState = async (cust, state) => {
    const changeStateCallback = async () => {
      const { error } = await changeCustomerStatus(cust._id, state, {});
      if (error) {
        handleError(error);
        return;
      }
      showSuccessToast("Stato cliente aggiornato con successo!");
      loadCustomers();
    };
    openEthosConfirmModal("Sicuro di voler cambiare lo stato del cliente?", changeStateCallback, null, "Conferma aggiornamento");
  };

  const addActivity = (customer) => {
    const doAddActivity = async (newActivity) => {
      const { error } = await saveNewActivity(newActivity);
      if (error) {
        handleError(error);
        return;
      }
      showSuccessToast("Attività registrata correttamente");
      loadCustomers();
    };

    openActivityModal(doAddActivity, configurations, customer, null, customer._id, null, users);
  };

  const addStrike = async (customer) => {
    const { error } = await addCustomerStrike(customer._id);
    if (error) {
      handleError(error);
      return;
    }
    showSuccessToast("Cliente aggiornato con successo!");
    loadCustomers();
  };

  const onSearchChange = debounce((value) => {
    setSearch(value);
  }, 500);

  const sortingColumn = (columnName) => {
    if (!!sortColumn) {
      if (sortColumn.column === columnName) {
        setSortColumn({
          column: columnName,
          direction: sortColumn.direction === 1 ? -1 : 1,
        });
      } else {
        setSortColumn({ column: columnName, direction: 1 });
      }
    }
  };

  const renderSortArrow = (columnName) => {
    if (sortFilter === null) {
      return <FontAwesomeIcon icon={faSort} style={{ opacity: ".50" }} className="ml-2" />;
    }
    if (!!sortColumn) {
      if (sortColumn.column === columnName) {
        return sortColumn.direction === 1 ? (
          <FontAwesomeIcon icon={faSortUp} className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faSortDown} className="ml-2" />
        );
      }
    }
  };

  useEffect(() => {
    if (!!sortColumn.column) {
      setSortFilter(`${sortColumn.column}:${sortColumn.direction}`);
    }
  }, [sortColumn]);

  const addNewCustomer = (data) => {
    clearFilter();
    searchRef.current.value = null;
    userRef.current.value = null;
    customerStatusRef.current.value = null;
    setCustomers(data);
  };

  const handleGenerateCSV = async () => {
    const { data, error, tmp } = await generateCSVFromTable(search, activeCustomFilter, myUser, LIMIT, offset, sortFilter);
    if (error) {
      handleError(error);
      return;
    }

    console.log(tmp);
    const buffer = new Uint8Array(data.buffer.Body.data);
    const filename = data.filename;

    const blob = new Blob([buffer], {});

    const url = window.URL.createObjectURL(blob);
    const element = document.createElement("a");

    element.href = url;
    element.download = filename;

    document.body.appendChild(element);

    element.click();
    element.remove();
  };

  return (
    <>
      <div className="list-customers-page">
        <div className="top-bar">
          <div className="row justify-content-center bg-light gap-3">
            <nav className="navbar navbar-expand-lg navbar-light  topbar customerFilter">
              <span className="navbar-brand ">View predefinite</span>
              <div className="btn-group" role="group" aria-label="View predefinite">
                {customFilters &&
                  customFilters.length > 0 &&
                  customFilters.map((item, index) => {
                    return (
                      <button
                        key={index + item.key}
                        className={"btn " + (item.key === activeCustomFilter ? "btn-primary" : "btn-outline-secondary")}
                        onClick={() => {
                          customerStatusRef.current.value = null
                          setActiveCustomFilter(item.key);
                          setOffset(0);
                        }}
                      >
                        {item.label}
                      </button>
                    );
                  })}
              </div>
            </nav>
            <div className="d-flex align-items-center justify-content-end clientFilter">
              <button
                onClick={() => {
                  handleGenerateCSV();
                }}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon className="mr-2" icon={faFileCsv} />
                Esporta in CSV
              </button>
            </div>
            <div className="d-flex flex-wrap gap-3 align-items-baseline mb-3 clientFilter">
              <div className="buttonArea d-flex flex-wrap justify-content-around selfSpaceAssing">
                <button className="btn btn-primary" onClick={() => setShowNewCustomer(!showNewCustomer)}>
                  Nuovo prospect
                </button>
                {["ADMIN"].indexOf(role) >= 0 && (
                  <button onClick={() => openUploadProspectsModal()} className="btn btn-primary">
                    Carica Prospects
                  </button>
                )}
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    clearFilter();
                    searchRef.current.value = null;
                    userRef.current.value = null;
                    customerStatusRef.current.value = null;
                  }}
                >
                  Resetta Filtri
                </button>
              </div>

              <div className="selectArea  d-flex flex-wrap justify-content-around selfSpaceAssing">
                {role === "ADMIN" && users && users.length ? (
                  <>
                    <select
                      ref={customerStatusRef}
                      className="form-select d-inline w-auto"
                      onChange={(e) => {
                        setActiveCustomFilter(e.target.value);
                        setOffset(0);
                      }}
                    >
                      <option value="null">Filtra per stato</option>
                      {customerStatus.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>

                    <select
                      ref={userRef}
                      className="form-select d-inline w-auto"
                      onChange={(e) => {
                        setMyUser(e.target.value);
                        setOffset(0);
                      }}
                    >
                      <option value="null">Filtra per utente</option>
                      {users.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.username}
                        </option>
                      ))}
                    </select>
                  </>
                ) : null}
              </div>

              <div className="searchArea selfSpaceAssing">
                <div className="row exportArea">
                  <div className="wrapper-input-group w-auto d-inline-block">
                    <div className="input-group">
                      <input
                        ref={searchRef}
                        className="form-control"
                        placeholder="Cerca un cliente"
                        onChange={(e) => onSearchChange(e.target.value)}
                      />
                      <input className="btn btn-primary" type="submit" value="Cerca" onClick={handleSearch} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="pagination-section">
          <ul className="pagination justify-content-center mt-3">{paginator}</ul>
        </nav>

        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th style={{ width: "5%" }}></th>
                {activeCustomFilter === "ATTESA-PAGAMENTO" ? <th></th> : null}
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("status");
                  }}
                >
                  Stato {renderSortArrow("status")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("first_name");
                  }}
                >
                  Nome {renderSortArrow("first_name")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("last_name");
                  }}
                >
                  Cognome {renderSortArrow("last_name")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("email");
                  }}
                >
                  Email {renderSortArrow("email")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("phone");
                  }}
                >
                  Telefono {renderSortArrow("phone")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("userdata.username");
                  }}
                >
                  Operatore {renderSortArrow("userdata")}
                </th>
                {activeCustomFilter === "DA-CONTATTARE" ? (
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      sortingColumn("reason");
                    }}
                  >
                    Motivazione {renderSortArrow("reason")}
                  </th>
                ) : null}
                {activeCustomFilter === "ATTESA-PAGAMENTO" ? (
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      sortingColumn("nextPaymentDate");
                    }}
                  >
                    Data Prossimo Pagamento {renderSortArrow("nextPaymentDate")}
                  </th>
                ) : null}
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("created_at");
                  }}
                >
                  Data Creazione {renderSortArrow("created_at")}
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sortingColumn("campaign");
                  }}
                >
                  Campagna origine {renderSortArrow("campaign")}
                </th>
                {showStrikes() && <th>Strikes</th>}
              </tr>
            </thead>
            <tbody>
              {customers
                ? customers.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="btn-group" role="group">
                            <button
                              id="btnGroupDrop1"
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Azioni
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <li>
                                <a href={`${props.match.path}/${item._id}`} className="dropdown-item">
                                  Modifica
                                </a>
                              </li>
                              {canBeSetToDiscarded(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "DISCARDED")} className="dropdown-item">
                                    Marca come DISCARDED
                                  </button>
                                </li>
                              )}
                              {canBeSetToProspect(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "PROSPECT")} className="dropdown-item">
                                    Marca come PROSPECT
                                  </button>
                                </li>
                              )}
                              {canBeSetToLead(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "LEAD")} className="dropdown-item">
                                    Marca come LEAD
                                  </button>
                                </li>
                              )}
                              {canBeSetToClosed(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "CLOSED")} className="dropdown-item">
                                    Marca come CLOSED
                                  </button>
                                </li>
                              )}
                              {canBeSetToCustomer(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "CUSTOMER")} className="dropdown-item">
                                    Marca come CUSTOMER
                                  </button>
                                </li>
                              )}
                              {canBeSetToRemarketing(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "REMARKETING")} className="dropdown-item">
                                    Marca come REMARKETING
                                  </button>
                                </li>
                              )}
                              {canBeSetToContenzioso(item) && (
                                <li>
                                  <button onClick={() => setCustomerState(item, "CONTENZIOSO")} className="dropdown-item">
                                    Marca come CONTENZIOSO
                                  </button>
                                </li>
                              )}
                              {canAddActivity(item) && (
                                <li>
                                  <button onClick={() => addActivity(item)} className="dropdown-item">
                                    Crea attività
                                  </button>
                                </li>
                              )}
                              {canAddStrike(item) && (
                                <li>
                                  <button onClick={() => addStrike(item)} className="dropdown-item">
                                    Registra chiamata senza risposta
                                  </button>
                                </li>
                              )}
                              <li>
                                <button onClick={() => handleDeleteCustomer(item._id)} className="dropdown-item text-danger">
                                  Elimina
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                        {activeCustomFilter === "ATTESA-PAGAMENTO" ? (
                          <td className="text-uppercase">
                            <button
                              className={"btn"}
                              onClick={() => {
                                openExpiredTranchesArrayModal(`${item.first_name} ${item.last_name}`, services, item);
                              }}
                            >
                              <FontAwesomeIcon icon={faExclamationTriangle} className={`${item.late_payment > 0 ? "text-danger" : ""}`} />
                            </button>
                          </td>
                        ) : null}
                        <td className="text-uppercase">{item.status}</td>
                        <td className="text-uppercase">{item.first_name}</td>
                        <td className="text-uppercase">{item.last_name}</td>
                        <td>{item.email}</td>
                        <td className="text-uppercase">{item.phone}</td>
                        <td>{item.userdata[0]?.username}</td>
                        {activeCustomFilter === "DA-CONTATTARE" ? <td>{item.reason || "-"}</td> : null}
                        {activeCustomFilter === "ATTESA-PAGAMENTO" ? (
                          <td className="text-uppercase">
                            <span className={`${item.late_payment ? "text-danger fw-bold" : null}`}>
                              {moment(item.nextPaymentDate).format("DD/MM/YYYY")}
                            </span>
                          </td>
                        ) : null}

                        <td className="text-uppercase">{moment(item.created_at).format("DD/MM/YYYY")}</td>
                        <td className="text-uppercase">{item.campaign ? item.campaign : "N.D."}</td>
                        {showStrikes() && <td className="text-uppercase">{item.strikes ? item.strikes : 0} </td>}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        {showNewCustomer ? (
          <NewCustomer
            setShowNewCustomer={setShowNewCustomer}
            setCustomers={() => {
              addNewCustomer();
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default ListCustomers;
