import React, { useCallback } from 'react'

import { Auth } from 'aws-amplify';
import { httpPost, httpDelete, httpCustomDelete, httpPatch, httpGet, httpCustomPatch, uploadFileToS3, httpPut } from '../http'
import { SERVICECUSTOMER_ENDPOINT, CUSTOMERS_ENDPOINT, PRACTICES_CQS_ENDPOINT, PRACTICES_LOANS_ENDPOINT, PRACTICES_DEBTS_ENDPOINT } from '../constants/API_ENDPOINT'
import { useEthosNotification } from './ethos-notification-context';

const ServiceCustomerContext = React.createContext()

function ServiceCustomerProvider(props) {
	const { registerPendingRequest, unregisterPendingRequest } = useEthosNotification();

	const getServiceCustomersByCustomerId = useCallback(async (customerId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${CUSTOMERS_ENDPOINT}/${customerId}/services`, jwtToken, {});

			return {
				data: result.data[0].serviceCustomerData,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getServiceCustomer = useCallback(async (serviceCustomerId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${SERVICECUSTOMER_ENDPOINT}/${serviceCustomerId}`, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);



	const newServiceCustomer = useCallback(async (data) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${SERVICECUSTOMER_ENDPOINT}/`, jwtToken, data);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const deleteServiceCustomer = useCallback(async (id) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpDelete(`${SERVICECUSTOMER_ENDPOINT}`, jwtToken, id);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);


	const updateServiceCustomer = useCallback(async (newObj) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${SERVICECUSTOMER_ENDPOINT}`, jwtToken, newObj, newObj._id);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const sendTranchePaymentLink = useCallback(async (scId, link) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			var params = {};
			params.paylink = link;
			const result = await httpPost(`${SERVICECUSTOMER_ENDPOINT}/${scId}/sendWA`, jwtToken, params);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const setServiceCustomerState = useCallback(async (sc, newState) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${SERVICECUSTOMER_ENDPOINT}/` + sc._id + '/next/' + newState, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const setServiceCustomerNewState = useCallback(async (sc, newState) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${SERVICECUSTOMER_ENDPOINT}/` + sc._id + '/nextServiceStatus/' + newState, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const setServiceCustomerStepCompleted = useCallback(async (sc, stepKey) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${SERVICECUSTOMER_ENDPOINT}/` + sc._id + '/completeStep/' + stepKey, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const uploadServiceCustomerDocument = useCallback(async (file, filename, expirationDate, type, scId, stepKey,customerId) => {

		if (!file) return;
		var re = /(?:\.([^.]+))?$/;
		const fileExtension = re.exec(file.name)[1];

		const body = {
			ext: fileExtension,
			filename: filename,
			customerId
		};

		if (expirationDate && !isNaN(expirationDate.getTime())) {
			body.expirationDate = expirationDate;
		}

		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			// const result = await httpPost(`${SERVICECUSTOMER_ENDPOINT}/${scId}/upload/${type}/${stepKey}`, jwtToken, body, {});

			const response = await httpPost(`${SERVICECUSTOMER_ENDPOINT}/${scId}/upload/url/${type}/${stepKey}`, jwtToken, body);

			await uploadFileToS3(response.data.url, file);

			body.fileId = response.data.fileId;
			const docResponse = await httpPut(`${SERVICECUSTOMER_ENDPOINT}/${scId}/upload/url/${type}/${stepKey}`, jwtToken, body);


			return {
				data: docResponse.data,
				error: null,
				meta: null,
				status: docResponse.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const removeServiceCustomerDocument = useCallback(async (scId, fileId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomDelete(`${SERVICECUSTOMER_ENDPOINT}/` + scId + "/deletefile/" + fileId, jwtToken);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const generateServiceCustomerStepReport = useCallback(async (scId, templateId, stepKey) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${SERVICECUSTOMER_ENDPOINT}/` + scId + "/report/" + templateId + "/" + stepKey, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const generateServiceCustomerReport = useCallback(async (scId, templateId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpCustomPatch(`${SERVICECUSTOMER_ENDPOINT}/${scId}/report/${templateId}`, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const generateLoansReport = useCallback(async (scId, templateId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${PRACTICES_LOANS_ENDPOINT}/` + scId + "/generate/" + templateId, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const generateDebtsReport = useCallback(async (scId, templateId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${PRACTICES_DEBTS_ENDPOINT}/` + scId + "/generate/" + templateId, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const generateCqsReport = useCallback(async (scId, templateId) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${PRACTICES_CQS_ENDPOINT}/` + scId + "/generate/" + templateId, jwtToken, {});
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const sendServiceCustomerReport = useCallback(async (scId, body, subject, reportId) => {
		try {
			registerPendingRequest();
			var params = {};
			params.body = body;
			params.subject = subject;
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPost(`${SERVICECUSTOMER_ENDPOINT}/` + scId + "/sendmail/" + reportId, jwtToken, params);
			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getServiceCustomers = useCallback(async (serviceName, limit, offset,sort,search,status,serviceStatus,bank,user) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`servicecustomers?filter=${serviceName}`, jwtToken, {
				limit: limit,
				offset: offset,
				sort: sort,
				search,
				status,
				serviceStatus,
				bank,
				user
			});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getServiceCustomersCsv = useCallback(async (serviceName, limit, offset,sort,search,status,serviceStatus,bank,user) => {
		try {
			registerPendingRequest();
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`servicecustomers/csv?filter=${serviceName}`, jwtToken, {
				limit: limit,
				offset: offset,
				sort: sort,
				search,
				status,
				serviceStatus,
				bank,
				user
			});

			return {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			return {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		} finally {
			unregisterPendingRequest();
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const addRelativeCustomer = useCallback(async(id,relation,relativeId) =>{
		try {
			registerPendingRequest()
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${SERVICECUSTOMER_ENDPOINT}/${id}/add/relation/${relation}/${relativeId}`, jwtToken,{},"");
			return{
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (error) {
			return {
				data: null,
				error: error,
				meta: null,
				status: null,
			};
		}finally{
			unregisterPendingRequest()
		}
	},[registerPendingRequest,unregisterPendingRequest])

	const removeRelativeCustomer = useCallback(async(id,relation,relativeId) =>{
		try {
			registerPendingRequest()
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpPatch(`${SERVICECUSTOMER_ENDPOINT}/${id}/remove/relation/${relation}/${relativeId}`, jwtToken,{},"");
			return{
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (error) {
			return {
				data: null,
				error: error,
				meta: null,
				status: null,
			};
		}finally{
			unregisterPendingRequest()
		}
	},[registerPendingRequest,unregisterPendingRequest])

	const getScreeningSummary = useCallback(async () => {
		try {
			registerPendingRequest()
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${SERVICECUSTOMER_ENDPOINT}/Screening/summary`, jwtToken,{});
			return{
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		}

		catch (error) {
			return {
				data: null,
				error: error,
				meta: null,
				status: null,
			};
		}
		finally{
			unregisterPendingRequest()
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	const getServiceCustomerSummary = useCallback(async (service,serviceStatus) => {
		try {
			registerPendingRequest()
			const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
			const result = await httpGet(`${SERVICECUSTOMER_ENDPOINT}/${service}/${serviceStatus? 'serviceStatusSummary' :'summary'}`, jwtToken,{});
			return{
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		}

		catch (error) {
			return {
				data: null,
				error: error,
				meta: null,
				status: null,
			};
		}
		finally{
			unregisterPendingRequest()
		}
	}, [registerPendingRequest, unregisterPendingRequest]);

	return (
		<ServiceCustomerContext.Provider
			value={{
				getServiceCustomersByCustomerId,
				getServiceCustomer,
				newServiceCustomer,
				deleteServiceCustomer,
				updateServiceCustomer,
				setServiceCustomerState,
				setServiceCustomerNewState,
				setServiceCustomerStepCompleted,
				getScreeningSummary,
				getServiceCustomerSummary,
				uploadServiceCustomerDocument,
				removeServiceCustomerDocument,
				generateServiceCustomerStepReport,
				generateServiceCustomerReport,
				generateCqsReport,
				generateLoansReport,
				generateDebtsReport,
				sendServiceCustomerReport,
				getServiceCustomers,
				getServiceCustomersCsv,
				sendTranchePaymentLink,
				addRelativeCustomer,
				removeRelativeCustomer,
			}}
			{...props}
		/>
	)
}
const useServiceCustomer = () => React.useContext(ServiceCustomerContext)

export { ServiceCustomerProvider, useServiceCustomer }